import Swiper from 'swiper/bundle';

window.addEventListener('load', function () {
  const toggleQuickLinksPopup = document.querySelector('#toggleQuickLinks');
  const QuickLinksPopup = document.querySelector('#QuickLinksPopup');
  const closePopup = document.querySelector('.quick_link-popup__close');

  function closeQuickLinksPopup() {
    QuickLinksPopup.classList.remove('opened');
    toggleQuickLinksPopup.setAttribute('aria-expanded', 'false');
    closePopup.setAttribute('aria-expanded', 'false');
    document.documentElement.classList.remove('lockedPosition');
  }

  function openQuickLinksPopup() {
    const openEvent = new Event('openQuickLinksPopup');
    QuickLinksPopup.classList.add('opened');
    toggleQuickLinksPopup.setAttribute('aria-expanded', 'true');
    closePopup.setAttribute('aria-expanded', 'true');
    window.dispatchEvent(openEvent);
    document.documentElement.classList.add('lockedPosition');
  }

  if (toggleQuickLinksPopup && closePopup) {
    toggleQuickLinksPopup.addEventListener('click', function () {
      if (QuickLinksPopup.classList.contains('opened')) {
        closeQuickLinksPopup();
      } else {
        openQuickLinksPopup();
      }
    });

    closePopup.addEventListener('click', function () {
      if (QuickLinksPopup.classList.contains('opened')) {
        closeQuickLinksPopup();
      }
    });
  }

  new Swiper('.fullwidth-header__carousel', {
    direction: 'horizontal',
    loop: true,

    pagination: {
      el: '.fullwidth-header__carousel__navigation .swiper-pagination',
      clickable: 'true',
    },

    navigation: {
      nextEl: '.fullwidth-header__carousel__navigation .swiper-button-next',
      prevEl: '.fullwidth-header__carousel__navigation .swiper-button-prev',
    },
  });

  const openVideoBtn = document.querySelector('.open-video-btn');

  if (openVideoBtn) {
    $(document).on('click', '.open-video-btn', function () {
      document
        .querySelector('.fullwidth-header__slide__video')
        .classList.add('video-playing');
      document.querySelector('header').classList.add('overlay-video-active');
      document.querySelector('body').classList.add('overlay-video-active');
      document
        .querySelector('.is-style-offset-image-with-logo')
        .classList.add('overlay-video-active');
      document.querySelector('.fullwidth-header__slide__video video').play();
    });
  }

  const closeVideoBtn = document.querySelector('.close-video-btn');

  if (closeVideoBtn) {
    $(document).on('click', '.close-video-btn', function () {
      document
        .querySelector('.fullwidth-header__slide__video')
        .classList.remove('video-playing');
      document.querySelector('header').classList.remove('overlay-video-active');
      document.querySelector('body').classList.remove('overlay-video-active');
      document
        .querySelector('.is-style-offset-image-with-logo')
        .classList.remove('overlay-video-active');
      document.querySelector('.fullwidth-header__slide__video video').pause();
    });
  }

  function heightBanner() {
    const banner = document.querySelector('.fullwidth-header-block');
    const bannerHeight = banner.offsetHeight;
    banner.style.setProperty('--BannerHeight', bannerHeight + 'px');
    document.documentElement.style.setProperty(
      '--BannerHeight',
      bannerHeight + 'px'
    );
  }

  heightBanner();

  window.addEventListener('resize', function () {
    heightBanner();
  });
});
